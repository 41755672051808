<template>
  <div class="purchasesPage">
    <div class="navBar">
      <div @click="$router.go(-1)" class="back">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
      </div>
      <div class="title">我的购买</div>
      <div class="rightBtn"></div>
    </div>

    <van-tabs
      v-model="activeName"
      @change="changeTab"
      background="#fff"
      title-active-color="rgb(255,103,143)"
      title-inactive-color="rgb(51,51,51)"
      class="vant_tabs"
      animated
      :swipeable="true"
    >
      <van-tab :title="item.name" v-for="item in tabs" :key="item.path" :name="item.path">
        <keep-alive>
          <router-view />
        </keep-alive>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { Tab, Tabs } from 'vant';

export default {
  name: 'myPurchases',
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
  },
  data() {
    return {
      activeName: '/myPurchases/movieList',
      tabs: [
        {
          path: '/myPurchases/movieList',
          name: '影视',
        },
        {
          path: '/myPurchases/videoList',
          name: '抖音',
        },
        {
          path: '/myPurchases/postList',
          name: '帖子',
        },
      ],
    };
  },
  created() {
    this.activeName = this.$route.path;
  },
  methods: {
    changeTab(name) {
      this.$router.replace(name);
    },
  },
};
</script>

<style lang="scss" scoped>
.purchasesPage {
  height: 100%;

  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #fff;
    box-sizing: border-box;
    border-bottom: 1px solid #e0e0e0;

    .back {
      width: 80px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 80px;
    }
  }

  .vant_tabs {
    height: 100%;

    /deep/ .van-tabs__wrap {
      height: 40px;
      margin: 0 auto 10px;
    }

    /deep/ .van-tabs__nav {
      // width: 240px;
      // height: 28px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
    }

    /deep/ .van-tab {
      width: 56px;
    }

    /deep/ .van-tabs__line {
      width: 20px;
      height: 4px;
      background: rgb(255, 103, 143);
    }

    /deep/ .van-tab__pane {
      height: 100%;
    }

    /deep/ .van-tab__pane-wrapper {
      height: 100%;
    }

    /deep/ .van-tabs__track {
      height: 100%;
    }

    /deep/ .van-tabs__content {
      height: 100%;
    }

    /deep/ .van-tab__text {
      font-size: 18px;
    }
  }
}
</style>
